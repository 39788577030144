






import Vue from 'vue'
import SettingsPage from './Settings.vue'
export default Vue.extend({
    name: 'SettingsIndex',
    components: {
        SettingsPage
    },
})
