






import Vue from 'vue';
import Settings from '../../components/settings/Settings.vue';
export default Vue.extend({
    name: 'SettingsPage',
    components: {
        Settings
    }
})
